.accuracy-table {
  .ant-card-body {
    padding: 0;
  }
  .accuracy-group {
    span {
      display: inline-block;
      min-width: 100px;
      font-weight: normal;
      color: rgba(255,255,255, .5);
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    font-weight: bold;
  }
}
